import styled from "@emotion/styled";

import { LiveTag } from "scmp-app/components/content/content-live-tag/styles";
import { SectionContainer } from "scmp-app/components/content/content-sections/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";

import SpotlightLogo from "./logo-spotlight.svg";

export const Container = styled.div`
  margin-block-start: 32px;
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 48px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const StyledLogo = styled(SpotlightLogo)``;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  ${LiveTag} {
    display: none;
  }
  ${SectionContainer} {
    display: block;
  }
`;
