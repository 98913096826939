/**
 * @generated SignedSource<<8e5893540343fab7ed871c88f262fc53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type spotlightQuery$data = {
  readonly spotlightQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "spotlightQuery";
};
export type spotlightQuery$key = {
  readonly " $data"?: spotlightQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"spotlightQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "spotlightQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "spotlightQuery",
  "selections": [
    {
      "alias": "spotlightQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "spotlightQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesBlockQueueItemsEdge"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "134ec2921fb54c142ae8cc05bf7ca811";

export default node;
