import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { spotlightQuery$key } from "scmp-app/queries/__generated__/spotlightQuery.graphql";

import { Container, LogoContainer, StyledLogo, StyledTopStoriesBlock } from "./styles";

type Props = {
  className?: string;
  reference: spotlightQuery$key;
};

export const HomeSpotlight: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const { spotlightQueue } = useFragment(
    graphql`
      fragment spotlightQuery on Query
      @argumentDefinitions(spotlightQueueName: { type: "String!" }) {
        spotlightQueue: queue(filter: { name: $spotlightQueueName }) {
          items(first: 4) {
            edges {
              ...topStoriesBlockQueueItemsEdge
              node {
                ... on Content {
                  entityId
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const spotlightItems = spotlightQueue?.items?.edges ?? [];
  const listOfArticles = (spotlightQueue?.items?.edges?.map(item => item.node.entityId) ?? []).join(
    ",",
  );

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: "homepage",
        widget_name: "scmp_spotlight",
      },
      subcategory: "widget",
    }),
    [listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Spotlight,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        <StyledTopStoriesBlock
          reference={spotlightItems}
          withCaption={false}
          onClick={(entityId, urlAlias) => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,

                destination_url: getAbsoluteUrl(urlAlias),
                page_type: "homepage",
                widget_name: "scmp_spotlight",
              },
              subcategory: "widget",
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeSpotlight.displayName = "HomeSpotlight";
